import { JobFilter } from '../../../__generated__/v2';

import type { VaccinationFilter } from '../../../hook/usePatientsContext';

export const convertFiltersToV2 = (filters: VaccinationFilter): JobFilter => {
    const result: JobFilter = {
        dynamoDBIndexName: 'jobType-index',
        jobType: {
            equals: 'vaccination',
        },
    };

    if (filters.consented) {
        result.consented = {
            in: filters.consented,
        };
    }
    if (filters.jobStatus) {
        result.assignmentStatusKey = {
            in: filters.jobStatus,
        };
    }
    if (filters.hubId) {
        result.hubId = {
            in: filters.hubId,
        };
    }
    if (filters.practice) {
        result.gpPracticeName = {
            in: filters.practice,
        };
    }
    if (filters.vaccinationCategory) {
        result.vaccinationCategoryId = {
            in: filters.vaccinationCategory,
        };
    }
    if (filters.vaccineManufacturer) {
        result.vaccinationManufacturerId = {
            in: filters.vaccineManufacturer,
        };
    }
    if (filters.flags) {
        result.flags = {
            in: filters.flags.map((item) => item.contains),
        };
    }
    if (filters.doseNumber) {
        result.doseNumber = {
            in: filters.doseNumber.map((item) => parseInt(item, 10)),
        };
    }
    if (filters.dateOfBirth) {
        result.dateOfBirth = {
            lte: filters.dateOfBirth[0].le,
            gte: filters.dateOfBirth[0].ge,
        };
    }
    if (filters.dateOfPreviousDose) {
        result.previousDoseDate = {
            lte: filters.dateOfPreviousDose.spread[0].and[1].and?.[0].dateOfDose1.le,
            gte: filters.dateOfPreviousDose.spread[0].and[1].and?.[1].dateOfDose1.ge,
        };
    }

    return result;
};
