import {
    AdminTypeV2,
    DispositionType,
    JobStatus,
    Pathway,
    Patient,
    PatientAlert,
} from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import moment from 'moment';

import { formatNameFirstMiddleLast, shouldBeSyncedToS1 } from '@doc-abode/helpers';

import { FormSteps } from '../../components/pages/ucr/forms/common';
import { IHcp, WarningType } from '../../interfaces/ucr';
import filterPatientAlerts from './filterPatientAlerts';
import { getMomentEndTime } from './getEndDateTime';
import getHcpName from './getHcpName';
import { isMultiAssigneeJob } from './isMultiAssigneeJob';

export enum WarningMessages {
    ADDRESS_WARNING = 'Address details could not be verified',
    PLANNED_START_IN_PAST = 'Planned start time of the job is in the past',
    AFTER_LATEST_TIME = 'The planned time is later than the indicated latest time',
    BEFORE_EARLIEST_TIME = 'The planned time is earlier than the indicated earliest time',
    MISSING_HCP1 = 'First staff member is not assigned',
    MISSING_HCP2 = 'Second staff member is not assigned',
    MISSING_S1_REFERRAL = 'This job will be synced to SystmOne but is not associated with a referral',
    SCHEDULE_CONFLICT_HCP = 'The patient already has a job scheduled within this time period',
}

export enum WarningCategories {
    PATIENT_SCHEDULING_CONFLICT = 'PATIENT_SCHEDULING_CONFLICT',
    PLANNED_START_IN_PAST = 'PLANNED_START_IN_PAST',
    AFTER_LATEST_TIME = 'AFTER_LATEST_TIME',
    BEFORE_EARLIEST_TIME = 'BEFORE_EARLIEST_TIME',
    MISSING_HCP1 = 'MISSING_HCP1',
    MISSING_HCP2 = 'MISSING_HCP2',
    MISSING_S1_REFERRAL = 'MISSING_S1_REFERRAL',
    HCP_SCHEDULING_CONFLICT = 'HCP_SCHEDULING_CONFLICT',
    HCP_AVAILABILITY_CONFLICT = 'HCP_AVAILABILITY_CONFLICT',
    WARNING_GEOLOCATION_MISSING = 'WARNING_GEOLOCATION_MISSING',
    INCOMPATIBLE_LANGUAGE_HCP1 = 'INCOMPATIBLE_LANGUAGE_HCP1',
    INCOMPATIBLE_LANGUAGE_HCP2 = 'INCOMPATIBLE_LANGUAGE_HCP2',
    INCOMPATIBLE_GENDER_HCP1 = 'INCOMPATIBLE_GENDER_HCP1',
    INCOMPATIBLE_GENDER_HCP2 = 'INCOMPATIBLE_GENDER_HCP2',
}

export function isJobInQualifyingStateForBreachOfTimeWarnings(jobStatus: JobStatus): boolean {
    return [JobStatus.PENDING, JobStatus.AVAILABLE, JobStatus.ACCEPTED, JobStatus.CURRENT].includes(
        jobStatus,
    );
}

export function isBreachOfLatestArrival(job: Patient): boolean {
    // If there is no time constraint, we cannot be in breach
    if (!job.availableTo) {
        return false;
    }

    // If the job is in certain states, we are not interested in breach of time warnings
    // Condition is incomplete, needs to account for dbl-up
    if (!isJobInQualifyingStateForBreachOfTimeWarnings(job.jobStatus)) {
        return false;
    }

    // If the calculated end time is after the latest time of arrival, we have a breach
    // Not using the endDateTime as the field is not reliably set; hence calculating startDateTime + duration
    if (moment(getMomentEndTime(job.startDateTime, job.duration)).isAfter(job.availableTo)) {
        return true;
    }

    // If the planned end time has passed and the job is not in progress (= qualifying states), we have a breach
    if (moment().isAfter(job.availableTo)) {
        return true;
    }

    return false;
}

export function isBreachOfEarliestArrival(job: Patient): boolean {
    // If there is no time constraint, we cannot be in breach
    if (!job.availableFrom) {
        return false;
    }

    // If the job is in certain states, we are not interested in breach of time warnings
    // Condition is incomplete, needs to account for dbl-up
    if (!isJobInQualifyingStateForBreachOfTimeWarnings(job.jobStatus)) {
        return false;
    }

    // If the planned startTime is prior to the earliest time of arrival, we have a breach
    if (moment(job.startDateTime).isBefore(job.availableFrom)) {
        return true;
    }

    return false;
}

export function hasUnresolvedPatientAlerts(job: Patient, patientAlerts: PatientAlert[]) {
    return filterPatientAlerts(patientAlerts, job.id).hasUnresolvedAlerts;
}

export function getGenderWarningText(hcp?: IHcp): string {
    if (!hcp) {
        return `The gender of the HCP does not match the patient's preference(s)`;
    }
    return `The gender of ${formatNameFirstMiddleLast({ firstName: hcp.firstName, lastName: hcp.lastName })} does not match the patient's preference(s)`;
}

export function hasGenderWarning(hcp?: IHcp, genderPreferences?: string[]): boolean {
    // If no HCP is assigned yet, there can't be a compatibility issue
    if (!hcp) {
        return false;
    }

    // If the patient has no preferences, there can't be a compatibility issue
    if (!genderPreferences?.length) {
        return false;
    }

    // If the patient explicitly indicates no preferences, there is no compatibility issue
    if (genderPreferences.includes('nopreference')) {
        return false;
    }

    // If the patient has preferences but the HCP has no gender, there is a compatibility issue
    if (genderPreferences && !hcp.gender) {
        return true;
    }

    return !genderPreferences.includes(hcp.gender);
}

export function getLanguageWarningText(hcp?: IHcp): string {
    if (!hcp) {
        return `The HCP does not speak the patient's language(s)`;
    }
    return `${formatNameFirstMiddleLast({ firstName: hcp.firstName, lastName: hcp.lastName })} does not speak the patient's language(s)`;
}

export function hasLanguageWarning(hcp?: IHcp, languagesSpoken?: string[]): boolean {
    // If no HCP is assigned yet, there can't be a compatibility issue
    if (!hcp) {
        return false;
    }

    // If the patient has no preferences, there can't be a compatibility issue
    if (!languagesSpoken?.length) {
        return false;
    }

    // If the patient has preferences but the HCP has no languages, there is a compatibility issue
    if (languagesSpoken && !hcp.preferences?.languages) {
        return true;
    }

    return !languagesSpoken.filter((preference) => {
        return hcp.preferences.languages?.includes(preference);
    }).length;
}

export function hasGeolocationError(warnings?: WarningType[]): boolean {
    if (!warnings) {
        return false;
    }

    if (warnings.length === 0) {
        return false;
    }

    const warningFound = warnings.find(
        (warning) => warning.category === WarningCategories.WARNING_GEOLOCATION_MISSING,
    );

    return !!warningFound;
}

export function hasCarRequiredWarning(job: Patient): boolean {
    return job.carRequired || false;
}

export function hasComplexCare(job: Patient): boolean {
    return job.careComplexity === 'complex';
}

export function hasPlannedStartTimeInThePast(job: Patient): boolean {
    const qualifyingJobStatus = [JobStatus.PENDING, JobStatus.ACCEPTED, undefined];

    // If there is no planned start time, the job cannot be in the past
    if (!job.startDateTime) {
        return false;
    }

    // If the current time prior to the planned start time, the job is not in the past
    if (moment().diff(job.startDateTime) < 0) {
        return false;
    }

    // Otherwise we need to check the status of the job, incl. for dbl-ups
    if (qualifyingJobStatus.includes(job.jobStatus)) {
        return true;
    }

    if (isMultiAssigneeJob(job) && qualifyingJobStatus.includes(job.buddyJobStatus)) {
        return true;
    }

    // Any other condition means the job is not in the past
    return false;
}

/**
 * function expects the caller to have checked whether S1 is enabled
 * @param job
 * @param adminTypes
 * @param pathways
 * @returns
 */
export function hasS1ReferralWarning(job: Patient, adminTypes: AdminTypeV2[], pathways: Pathway[]) {
    const shouldBeSynced = shouldBeSyncedToS1(job, pathways, adminTypes);

    if (!shouldBeSynced) {
        return false;
    }

    return !job.systmOneRef;
}

interface ITransformBackendWarnings {
    warnings?: WarningType[];
    users?: IHcp[];
}

export function transformBackendWarnings({ warnings, users = [] }: ITransformBackendWarnings) {
    const transformedWarnings: WarningType[] = [];

    warnings?.forEach((warning) => {
        let message = '';
        let appliesTo: FormSteps[] = [];

        if (warning.category === WarningCategories.PATIENT_SCHEDULING_CONFLICT) {
            message = WarningMessages.SCHEDULE_CONFLICT_HCP;
            appliesTo = [FormSteps.PATIENT, FormSteps.ACTIVITY, FormSteps.CARE];
        } else if (warning.category === WarningCategories.WARNING_GEOLOCATION_MISSING) {
            message = WarningMessages.ADDRESS_WARNING;
            appliesTo = [FormSteps.PATIENT];
        } else if (warning.category === WarningCategories.HCP_SCHEDULING_CONFLICT) {
            message = `${getHcpName(users, warning.data?.hcpId)} has another job within this time period`;
            appliesTo = [FormSteps.ACTIVITY, FormSteps.CARE];
        } else if (warning.category === WarningCategories.HCP_AVAILABILITY_CONFLICT) {
            message = `The job is outside of ${getHcpName(
                users,
                warning.data?.hcpId,
            )} working hours`;
            appliesTo = [FormSteps.ACTIVITY, FormSteps.CARE];
        }

        if (message !== '') {
            transformedWarnings.push({
                category: warning.category,
                isActive: true,
                message,
                showOnSection: appliesTo,
            });
        }
    });

    return transformedWarnings;
}

interface IFilterDuplicateWarningMessages {
    warnings?: WarningType[];
}

export function filterDuplicateWarningMessages({ warnings = [] }: IFilterDuplicateWarningMessages) {
    const filteredWarnings: WarningType[] = [];

    warnings?.forEach((warning) => {
        if (
            !filteredWarnings.find((filteredWarning) => filteredWarning.message === warning.message)
        ) {
            filteredWarnings.push(warning);
        }
    });

    return filteredWarnings;
}

interface IFilterWarningMessagesBySection {
    warnings?: WarningType[];
    sectionShown?: FormSteps;
}

export function filterWarningMessagesBySection({
    warnings = [],
    sectionShown,
}: IFilterWarningMessagesBySection) {
    if (!sectionShown) {
        return warnings;
    }

    return warnings.filter((warning) => warning.showOnSection?.includes(sectionShown));
}

interface ITransformFormikValuesToPatientForWarningValidation {
    values: FormikValues;
    isAdmin?: boolean;
}

export function transformFormikValuesToPatientForWarningValidation({
    values,
    isAdmin = false,
}: ITransformFormikValuesToPatientForWarningValidation) {
    let dateOfVisit = null;
    let startDateTime = null;

    if (values.visitDate) {
        dateOfVisit = moment(values.visitDate).toISOString();
        startDateTime = moment(values.visitDate)
            .set({
                hour: Number(moment(values.startTime).hour()),
                minutes: Number(moment(values.startTime).minutes()),
            })
            .toISOString();
    }

    let earliestDateOfVisit;
    let availableFrom = null;
    let availableTo = null;

    if (values.earliestDateOfVisit) {
        earliestDateOfVisit = moment(values.earliestDateOfVisit).format('MM-DD-YYYY');

        const earliestHours = values?.availableFrom ? moment(values.availableFrom).hour() : '00';
        const earliestMinutes = values?.availableFrom
            ? moment(values.availableFrom).minute()
            : '00';

        availableFrom = moment(earliestDateOfVisit, 'MM-DD-YYYY')
            .set({
                hour: Number(earliestHours),
                minute: Number(earliestMinutes),
                seconds: 0,
                milliseconds: 0,
            })
            .toISOString();

        const latestHour = values?.availableTo ? moment(values.availableTo).hour() : '00';
        const latestMinutes = values?.availableTo ? moment(values.availableTo).minute() : '00';

        availableTo = moment(earliestDateOfVisit, 'MM-DD-YYYY')
            .set({
                hour: Number(latestHour),
                minute: Number(latestMinutes),
                seconds: 0,
                milliseconds: 0,
            })
            .toISOString();
    }

    let referralPathway = values.referralPathway;
    let disposition = values.disposition;
    let activityType = undefined;

    if (isAdmin) {
        referralPathway = undefined;
        disposition = DispositionType.ADMIN;
        activityType = values.activityType;
    }

    return {
        id: values.id,
        // Patient section
        nhsNumber: values.nhsNumber,
        postCode: values.postCode,
        latitude: values.latitude,
        longitude: values.longitude,
        staffPreferredGender: values.staffPreferredGender,
        languagesSpoken: values.languagesSpoken,

        // Referral details
        systmOneRef: values.systmOneRef,

        // Care or activity details - Timings
        referralPathway,
        disposition,
        activityType,
        dateOfVisit,
        startDateTime,
        duration: values.duration,
        earliestDateOfVisit,
        availableFrom,
        availableTo,

        // Care or activity details - assignment, status and timings
        staffRequired: values.staffRequired,
        hcpId: values.hcpId,
        buddyId: values.buddyId,
        jobStatus: values.jobStatus,
        buddyJobStatus: values.buddyJobStatus,

        arrivedDateTime: values.arrivedDateTime,
        finishedDateTime: values.finishedDateTime,
        buddyArrivedDateTime: values.buddyArrivedDateTime,
        buddyFinishedDateTime: values.buddyFinishedDateTime,
    } as Patient;
}
