import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Hub, RoutePatientInput, Vaccination } from '@doc-abode/data-models';
import useStores from '../../../../../../hook/useStores';
import { vaccinationRouteStatusAllowsRecalculation } from '../../../../../../models/PatientRemoveFromRoute';
import RootStore from '../../../../../../stores/RootStore';
import {
    checkUpdatedRoute,
    confirmUpdatedRoute,
    updateRoute,
    removeVaccinationFromRouteNoRecalculation,
} from '../../../../../../api/routes/routesApi';
import { formatPatientForRoute, getWarnings } from '../../../utils';
import { VaccinationRoute, VaccinationWithWarnings } from '../../../types';
import {
    getFilteredRoutesBetterFunctionNameToFollow,
    getTitle,
    makeHandleClosePickupRecalculationOptionsDialog,
    RouteUpdateCheckResponse,
    showFooterIfWeHaveNewItineraryAndWeAreNotConfirmingRoute,
    vaccinationRouteHasPickup,
} from './updateRouteHelpers';
import AppToaster from '../../../../../modules/helpers/Toaster';
import { ApolloQueryResult, OperationVariables, useLazyQuery } from '@apollo/client';
import { GET_JOB_BY_ID, GET_ROUTE_BY_ID } from '../../../../../../graphql/queries/jobs';
import { graphqlErrorHandler } from '../../../../common/errorHandling/graphqlErrorHandler';

interface Props {
    deselect: (id: string) => void;
    recalculateRoute: boolean;
    addPatients: boolean;
    allPatients: Vaccination[];
    patientsToAdd: Vaccination[];
    setVaccinations: () => void;
    refetchRoutes: (
        variables?: Partial<OperationVariables> | undefined,
    ) => Promise<ApolloQueryResult<any>>;
}

const useUpdateRouteModel = ({
    deselect,
    recalculateRoute,
    addPatients,
    allPatients,
    patientsToAdd,
    setVaccinations,
    refetchRoutes,
}: Props) => {
    const {
        RootStore: {
            routesStore: { allJobs: routes, editJob },
            userStore: { getAuthToken },
            configStore: {
                hubs,
                clientKeys,
                vaccinationDetails,
                vaccinationDuration,
                doseInterval,
            },
            lovsStore: { hcpType },
        },
    } = useStores<{ RootStore: RootStore }>();
    const { state } = useLocation<{
        routeType: string;
        hub: Hub;
        selectedStartTime: string;
        userId: string;
        patient: Vaccination;
        route: VaccinationRoute;
    }>();

    const [selectedRoute, setSelectedRoute] = useState<VaccinationRoute>(state.route);
    const [newItinerary, setNewItinerary] = useState<RouteUpdateCheckResponse | null>(null);
    const [multipleHubWarning, setMultipleHubWarning] = useState(false);
    const [removeRequestMade, setRemoveRequestMade] = useState(false);
    const [didError, setDidError] = useState<{ message: string; details: string[] } | null>(null);
    const [unscheduledByCapacity, setUnscheduledByCapacity] = useState<Vaccination[]>([]);
    const [unscheduledByOptimisation, setUnscheduledByOptimisation] = useState<Vaccination[]>([]);
    const [removedByOptimisation, setRemovedByOptimisation] = useState<Vaccination[]>([]);
    // todo not sure if removedByUser: Vaccination[] looking at the code it should probably be removedByUser: Vaccination
    const [removedByUser, setRemovedByUser] = useState<Vaccination[]>([]);
    const [emptyItinerary, setEmptyItinerary] = useState(false);
    const [cannotRecalculate, setCannotRecalculate] = useState(false);
    const [mostRecentPreviousDose, setMostRecentPreviousDose] = useState('');
    // if a route is to be recalculated and the route has a pickup:  show the recalculation options modal (PickupRecalculationOptionsDialog)
    const [showPickupRecalculationOptionsDialog, setShowPickupRecalculationOptionsDialog] =
        useState(false);
    const [newHub, setNewHub] = useState<Hub | null>(null);
    const [patientsWithWarnings, setPatientsWithWarnings] = useState<VaccinationWithWarnings[]>([]);
    const [confirmingRoute, setConfirmingRoute] = useState(false);
    // LOADING SPINNER AND MESSAGE
    const [loaderSpinner, setLoaderSpinner] = useState<{ message: string; show: boolean }>({
        message: 'Getting data ...',
        show: true,
    });
    const [showVaccinationRemovedSuccessfully, setShowVaccinationRemovedSuccessfully] =
        useState<boolean>(false);

    const history = useHistory();

    const [fetchJob] = useLazyQuery(GET_JOB_BY_ID, {
        fetchPolicy: 'network-only',
        onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
    });

    const [fetchRoute] = useLazyQuery(GET_ROUTE_BY_ID, {
        fetchPolicy: 'network-only',
        onError: ({ graphQLErrors }) => graphqlErrorHandler({ graphQLErrors }),
    });

    // why do we need useCallback?
    const resetPatient = useCallback(
        async (patient: Vaccination | null | undefined) => {
            if (!patient) {
                return;
            }

            try {
                await editJob({
                    ...patient,
                    jobStatus: 'PENDING',
                    hcpId: undefined,
                    hcpSub: undefined,
                    hcpName: undefined,
                    acceptedDateTime: undefined,
                    madeCurrentDateTime: undefined,
                    itineraryId: undefined,
                });
            } catch (err: any) {
                console.error(err.message);
                setDidError(err.message);
            }
        },
        [editJob],
    );

    const onRemoveWarningPatients = () => {
        patientsWithWarnings.forEach(({ id }) => deselect(id));
    };
    const onRequestUpdatedRoute = useCallback(
        /**
         *  calls the api via src/api/routesApi.js::updateRoute
         */
        async (
            route: VaccinationRoute,
            body: { [key: string]: any },
            patient?: Vaccination | null,
        ) => {
            setLoaderSpinner({ message: 'Getting data ...', show: true });
            try {
                const authToken = await getAuthToken();
                // todo deal with route.itineraryId being undefined. just done a lazy default to empty string for now
                const itineraryId = route.itineraryId ?? '';
                // Send the update information
                const response = await updateRoute(authToken, itineraryId, body);
                const { requestId } = response;
                let timeout = response.callbackInSeconds;

                const queryRoutes: () => void = async () => {
                    const routeUpdateCheckResponse: RouteUpdateCheckResponse = await new Promise(
                        (resolve) =>
                            setTimeout(async () => {
                                try {
                                    const response = await checkUpdatedRoute(
                                        authToken,
                                        itineraryId,
                                        requestId,
                                        body.selectedStartTime,
                                    );
                                    resolve(response);
                                } catch (err: any) {
                                    let error;
                                    try {
                                        error = JSON.parse(err);
                                    } catch (err) {}

                                    if (
                                        error?.message ===
                                        'Could not generate an itinerary with the given criteria'
                                    ) {
                                        if (!recalculateRoute) {
                                            await resetPatient(patient);
                                            await editJob({ ...route, itinerary: undefined });
                                        }
                                        setCannotRecalculate(true);
                                    } else {
                                        console.error(err.message);
                                        setDidError(error);
                                    }
                                }
                            }, timeout * 1000),
                    );

                    // looks like we recursively check for the updated route.
                    // If it's not been provided after the first x seconds we try again
                    // if routeUpdateCheckResponse.callbackInSeconds exists then that means try again
                    if (routeUpdateCheckResponse.callbackInSeconds) {
                        timeout = routeUpdateCheckResponse.callbackInSeconds;

                        // try again to get the route now it's been updated
                        return queryRoutes();
                    }

                    // We got the result we wanted, the updated route,  now do something with it.
                    if (
                        addPatients ||
                        recalculateRoute ||
                        // We have removed a patient and the route should be recalculated.
                        // Removing a patient is currently inferred as we do not explicitly say we are removing a patient (via passed props),
                        // its just that addPatients and recalculateRoute are both false.
                        // Should that change, it may be better to use
                        // PatientRemoveFromRoute.vaccinationRouteShouldBeRecalculatedWhenRemovingPatient
                        vaccinationRouteStatusAllowsRecalculation({
                            vaccinationRoute: route,
                        })
                    ) {
                        const unscheduledByCapacity: Vaccination[] = [];
                        const unscheduledByOptimisation: Vaccination[] = [];
                        const removedByOptimisation: Vaccination[] = [];
                        const removedByUser: Vaccination[] = [];
                        if (
                            // VSU-2078 bit of a hack, I think that this makes sense that we removedByUser the patient being removed, and that it's done here
                            // it looks like the api does not need to know about it at this stage.
                            vaccinationRouteStatusAllowsRecalculation({
                                vaccinationRoute: route,
                            }) &&
                            patient &&
                            body?.removePatients?.includes(patient.id)
                        ) {
                            removedByUser.push(patient);
                        }

                        await Promise.all(
                            routeUpdateCheckResponse.unscheduledItems.map(
                                async ({ id, reason }) => {
                                    let patient = allPatients.find((patient) => patient.id === id);
                                    if (!patient) {
                                        const response = await fetchJob({
                                            variables: {
                                                id,
                                            },
                                        });

                                        patient = response.data.getJob as Vaccination;
                                    }

                                    if (patient) {
                                        if (reason === 'CAPACITY') {
                                            unscheduledByCapacity.push(patient);
                                        } else if (reason === 'OPTIMISATION') {
                                            if (
                                                routeUpdateCheckResponse.removedPatients.some(
                                                    ({ id }) => id === patient?.id,
                                                ) &&
                                                !recalculateRoute
                                            ) {
                                                removedByOptimisation.push(patient);
                                            } else {
                                                unscheduledByOptimisation.push(patient);
                                            }
                                        } else if (reason === 'HCP_ABORTED') {
                                            removedByUser.push(patient);
                                        }
                                    }
                                },
                            ),
                        );
                        setNewItinerary(routeUpdateCheckResponse);
                        setLoaderSpinner({ message: '', show: false });
                        setUnscheduledByCapacity(unscheduledByCapacity);
                        setUnscheduledByOptimisation(unscheduledByOptimisation);
                        setRemovedByOptimisation(removedByOptimisation);
                        setRemovedByUser(removedByUser);
                    }
                };
                // update and then get the updated recalculated route
                queryRoutes();
            } catch (err: any) {
                let error;
                console.log('err', err);
                try {
                    error = JSON.parse(err.message);
                } catch (err) {
                    /* TODO?  something with the error?*/
                }

                if (
                    error?.message === 'Requested operation would result in itinerary with no items'
                ) {
                    await resetPatient(patient);
                    await editJob({ ...route, itinerary: undefined });
                    setEmptyItinerary(true);
                    setDidError(error);
                } else {
                    console.error(err.message);
                    setDidError(error);
                }
                setLoaderSpinner({ message: '', show: false });
            }
        },
        [getAuthToken, addPatients, recalculateRoute, resetPatient, editJob, allPatients, fetchJob],
    );

    useEffect(() => {
        if (recalculateRoute) {
            const { route, selectedStartTime, hub, userId } = state;

            let routeParameters = { ...route } as VaccinationRoute;

            if (!routeParameters.hcpId) {
                routeParameters.hcpId = undefined;
            }

            setSelectedRoute(routeParameters);
            const requestBody = {
                selectedStartTime,
                userId,
                ...(hub && { hub: { address: hub.address } }),
            };
            if (hub) {
                setNewHub(hub);
            }
            if (confirmingRoute) {
                return;
            }
            if (vaccinationRouteHasPickup({ vaccinationRoute: routeParameters })) {
                // route has at least one pick up, and we are explicitly (passed in props) recalculating
                // so we need to show the recalculation dialog
                setShowPickupRecalculationOptionsDialog(true);
            } else {
                // No pickups on the route
                onRequestUpdatedRoute(routeParameters, requestBody, null);
            }
            return;
        }
        // is not add patients and not removeRequestMade and not recalculateRoute (returns above if its recalculateRoute)
        // We will request and update of the route via onRequestUpdatedRoute  (this is a recalculation)
        // looks like removeRequestMade is set to true once this section of code has been executed to prevent
        // removing the same patient
        // this bit of code is executed when you are removing a patient,  but it implied by simply not being
        // addPatients or recalculate as passed in the components props
        // looks like we need to check here if we do the recalculation of the route based on the VaccinationRoute.jobStatus
        // if we are removing a patient.
        // added && !recalculateRoute so we know its part of the required conditions for this bit of code to execute (for refactoring purposes
        // the bock above that triggers for recalculateRoute ===  true may end up being moved
        if (!addPatients && !removeRequestMade && !recalculateRoute) {
            // REMOVING A PATIENT
            const { patient, route } = state;
            setRemovedByUser([patient]);
            setSelectedRoute(route);
            setRemoveRequestMade(true);

            if (vaccinationRouteStatusAllowsRecalculation({ vaccinationRoute: route })) {
                // VSU-2078 if recalculating route and pickups on route show
                // showPickupDetailsDialog
                // handleClosePickupDetailsDialog then needs to call onRequestUpdatedRoute
                // this bit of code is similar to the recalculateRoute section
                // be we are using state.route  rather than state.routeDetails <- try to find out what the difference is.
                if (vaccinationRouteHasPickup({ vaccinationRoute: route })) {
                    // route has at least one pick up, show the recalculation dialog for further user input.
                    setShowPickupRecalculationOptionsDialog(true);
                } else {
                    // start the removal process on the api.
                    onRequestUpdatedRoute(route, { removePatients: [patient.id] }, patient);
                }
            } else {
                // VSU-2078
                // Withdraw without a recalculation
                const handleRemoveVaccinationNoRecalculation = async () => {
                    setLoaderSpinner({ message: 'Removing patient from route...', show: true });
                    const authToken = await getAuthToken();
                    await removeVaccinationFromRouteNoRecalculation(authToken, {
                        vaccinationId: patient.id,
                        vaccinationRouteId: route.id,
                    });
                };

                handleRemoveVaccinationNoRecalculation()
                    .catch((error) => {
                        // todo how do we handle this sort of error.
                        console.error(error);
                    })
                    .then(() => {
                        setLoaderSpinner({ message: '', show: false });
                        setShowVaccinationRemovedSuccessfully(true);
                    });
            }
            return;
        } else if (!addPatients) {
            return;
        }

        if (addPatients) {
            let multipleHubWarning = false;

            patientsToAdd.forEach((job) => {
                const { hubId } = job;
                const hub = hubs.find((hub) => hub.id === hubId);

                const { compatibleWith = [] } = hub || {};
                const otherHubIds = patientsToAdd
                    .filter((otherJob) => otherJob.id !== job.id)
                    .map((otherJob) => otherJob.hubId);

                if (
                    otherHubIds.some(
                        (hubId) => hubId !== hub?.id && !compatibleWith.includes(hubId),
                    )
                ) {
                    multipleHubWarning = true;
                }
            });

            setMultipleHubWarning(multipleHubWarning);
        }
    }, [
        hubs,
        patientsToAdd,
        addPatients,
        state,
        onRequestUpdatedRoute,
        removeRequestMade,
        recalculateRoute,
        confirmingRoute,
        getAuthToken,
    ]);
    // WARNINGS. Patients being added may have a warning.
    useEffect(() => {
        const fetchWarnings = async () => {
            const warnings = await getWarnings(
                patientsToAdd,
                null,
                doseInterval,
                vaccinationDetails,
            );
            setPatientsWithWarnings(warnings);
            setLoaderSpinner({ message: '', show: false });
        };

        if (patientsToAdd?.length > 0) {
            fetchWarnings();
        }
    }, [patientsToAdd, vaccinationDetails, doseInterval]);

    // I think this gets the date of the most recent previous dose from the group of patients that are to be added.
    // We then use the date with the minDaysSincePreviousDose to filter the routes
    useEffect(() => {
        let mostRecentPreviousDose = '';
        patientsToAdd.forEach(({ dateOfPreviousDose }) => {
            if (!dateOfPreviousDose) {
                return;
            }

            if (
                mostRecentPreviousDose === '' ||
                moment(dateOfPreviousDose).isAfter(moment(mostRecentPreviousDose))
            ) {
                mostRecentPreviousDose = dateOfPreviousDose;
            }
        });
        setMostRecentPreviousDose(mostRecentPreviousDose);
    }, [patientsToAdd]);

    const routeType = state?.routeType;
    const filteredRoutes = getFilteredRoutesBetterFunctionNameToFollow({
        hubs,
        vaccinations: patientsToAdd,
        vaccinationRoutes: routes,
        vaccinationRouteType: routeType,
        mostRecentPreviousDose,
    });

    const removedPatients = [
        ...removedByOptimisation,
        ...removedByUser,
        ...unscheduledByOptimisation,
    ];

    const handleOnClickUpdateRouteAddPatients = async (id: string) => {
        // I'm not sure that the setShowLoaderSpinner state change matter as its gets
        // immediately set to false. (nb this flow is from the original UpdateRoute code)
        setLoaderSpinner({ message: 'Fetching route details...', show: true });
        const response = await fetchRoute({
            variables: {
                id,
            },
        });

        const route = response.data.getJob as VaccinationRoute;
        setSelectedRoute(route);
        setLoaderSpinner({ message: '', show: false });

        // If there were no pickups on the route before then we don't need to show the modal recalculation options
        if (!vaccinationRouteHasPickup({ vaccinationRoute: route })) {
            let addPatients: RoutePatientInput[] = [];
            if (addPatients) {
                addPatients = patientsToAdd.reduce((patients, job) => {
                    const formattedPatient = formatPatientForRoute({
                        patient: job,
                        selectedDate: route?.itinerary.route.startTime,
                        vaccinationDuration,
                        vaccinationDetails,
                        routeType,
                        jobs: allPatients,
                    });

                    if (formattedPatient) {
                        patients.push(formattedPatient);
                    }

                    return patients;
                }, [] as RoutePatientInput[]);
            }
            await onRequestUpdatedRoute(route, { addPatients });
        } else {
            setShowPickupRecalculationOptionsDialog(true);
        }
    };

    const oldRoute = selectedRoute?.itinerary.route;
    const newRoute = newItinerary?.route;

    const handleConfirmUpdateRoute = async () => {
        setConfirmingRoute(true);
        setLoaderSpinner({ message: 'Confirming route...', show: true });
        setShowPickupRecalculationOptionsDialog(false);

        let addedPatients;
        if (addPatients) {
            const existingPatients = selectedRoute?.itinerary.instructions
                .map((instruction) => instruction.itineraryItem?.name)
                .filter((patient) => patient);

            addedPatients = newItinerary?.instructions
                .filter((instruction) => instruction.instructionType !== 'Pickup')
                .map((instruction) => instruction.itineraryItem?.name)
                .filter((patient) => patient && !existingPatients?.includes(patient));
        }

        try {
            const updatedJob = await editJob({ ...selectedRoute, itinerary: undefined });
            const authToken = await getAuthToken();
            await confirmUpdatedRoute(authToken, newItinerary?.itineraryId, {
                route: selectedRoute?.id,
                addedPatients,
                removedPatients: removedPatients.map(({ id }) => id),
                hcpTypes: hcpType.map(({ value }) => value),
                hubId: newHub?.id,
                version: updatedJob?.version,
            });

            await refetchRoutes();
            patientsToAdd?.forEach(({ id }) => deselect(id));
            setVaccinations();
            history.push(`/vaccinations/routes/${selectedRoute?.id}`, { reload: true });
        } catch (err) {
            console.error(err);
            setLoaderSpinner({ message: '', show: false });
            setConfirmingRoute(false);
            AppToaster.show({
                message: (err as Error).message || 'Job updated failed.',
                intent: 'danger',
            });
        }
    };

    // passed every time we display PickupRecalculationOptionsDialog
    // triggered onClose PickupRecalculationOptionsDialog which is a "we don't do anything" action
    // PickupRecalculationOptionsDialogPickupRecalculationOptionsDialog::onConfirm is the function that "does something"

    // triggers a state change, so we get a re-render here, which is how any changes that have been made in PickupRecalculationOptionsDialog
    // will get processed.
    // This sends us to Routes with the route id which would then
    // send us to RouteDetails
    const handleClosePickupRecalculationOptionsDialog =
        makeHandleClosePickupRecalculationOptionsDialog({
            addPatients,
            history,
            selectedRoute,
            recalculateRoute,
            setShowPickupRecalculationOptionsDialog,
        });

    const title = getTitle({
        recalculateRoute,
        addPatients,
    });
    const showFooter = showFooterIfWeHaveNewItineraryAndWeAreNotConfirmingRoute(
        newItinerary,
        confirmingRoute,
    );
    let patientsToAddPickupRecalculationOptionsDialog: Vaccination[] = [];
    if (addPatients) {
        patientsToAddPickupRecalculationOptionsDialog = patientsToAdd;
    }

    const { show: loaderSpinnerShow, message: loaderSpinnerMessage } = loaderSpinner;

    return {
        clientKeys,

        // tested
        oldRoute,
        newRoute,
        multipleHubWarning,

        // TO BE TESTED
        showPickupRecalculationOptionsDialog,
        showVaccinationRemovedSuccessfully,
        onRemoveWarningPatients,
        filteredRoutes,
        handleOnClickUpdateRouteAddPatients,
        handleConfirmUpdateRoute,
        handleClosePickupRecalculationOptionsDialog,
        title,
        showFooter,
        patientsToAddPickupRecalculationOptionsDialog,
        loaderSpinnerShow,
        loaderSpinnerMessage,
        removedPatients,
        removedByUser,
        newItinerary,
        removedByOptimisation,
        unscheduledByOptimisation,
        getAuthToken,
        hubs,
        patientsWithWarnings,
        selectedRoute,
        setShowPickupRecalculationOptionsDialog,
        onRequestUpdatedRoute,

        // untested/ hard to test
        didError,
        unscheduledByCapacity,
        emptyItinerary,
        cannotRecalculate,

        // below only exported for testing purposes
        setNewItinerary,
        confirmingRoute,
        removeRequestMade,
    };
};

export default useUpdateRouteModel;
