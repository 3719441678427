import { useMutation } from '@apollo/client';
import { FC, useCallback } from 'react';

import { formatNameLastMiddleFirst } from '@doc-abode/helpers';

import { UPDATE_PATIENT } from '../../../../../graphql/queries/patients';
import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Button, ButtonColors } from '../../../../v2/components';

interface IProps {
    onClose: () => void;
}

export const DialogDischarge: FC<IProps> = ({ onClose }) => {
    const {
        RootStore: {
            patientListStore: { admissionPatient, updatePatientAdmission },
            userStore: { user },
        },
    } = useStores() as { RootStore: any };

    const [updatePatient] = useMutation(UPDATE_PATIENT);

    const performDischarge = useCallback(async () => {
        if (admissionPatient) {
            try {
                await updatePatient({
                    variables: {
                        input: {
                            admission: {
                                nhsNumber: `${admissionPatient?.nhsNumber}`,
                                isAdmitted: false,
                                dischargedBy: user.username,
                            },
                        },
                    },
                });
                updatePatientAdmission(admissionPatient, false);
                AppToaster.show({
                    message: `${formatNameLastMiddleFirst(admissionPatient)} has been discharged.`,
                    intent: 'success',
                });
                onClose();
            } catch (err) {
                console.error('Error updating patient', err);
            }
        }
    }, [admissionPatient, updatePatient, user.username, updatePatientAdmission, onClose]);

    return (
        <div>
            <p>
                Do you really want to discharge{' '}
                <b className="ucr__patient_list_highlight_dialog_name">
                    {formatNameLastMiddleFirst(admissionPatient || {})}
                </b>
                ?
            </p>
            <p>
                This patient will be removed from the list of current patients but all details will
                be retained.
            </p>
            <Button
                name="Cancel"
                color={ButtonColors.GREY}
                clickEvent={onClose}
                className="ucr__dialog-discharge-buttons ucr__dialog-discharge-cancel"
            />
            <Button
                name="Confirm"
                clickEvent={performDischarge}
                className="ucr__dialog-discharge-buttons ucr__dialog-discharge-confirm"
            />
        </div>
    );
};
