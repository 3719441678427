import {
    Alert,
    Callout,
    Icon,
    Radio,
    RadioGroup,
    ButtonGroup,
    Button,
    Switch,
    FormGroup,
    InputGroup,
} from '@blueprintjs/core';
import { useState, useMemo } from 'react';
import { Vaccination, JobStatus } from '@doc-abode/data-models';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';

import Loader from '../../modules/helpers/Loader';
import PatientsTable from './patients/PatientsTable';
import Filters from '../../modules/jobs/list/Filters';
import useStores from '../../../hook/useStores';
import filterOptions from './patients/filterOptions';
import PatientDetails from './patients/PatientDetails';
import AddPatient from './patients/AddPatient';
import ImportPatients from './patients/ImportPatients';
import { routeTypes } from './utils';
import usePatientsContext from '../../../hook/usePatientsContext';
import {
    arePatientsCompatible,
    filterPatientSelection,
    FilterType,
} from '../../../helpers/patientSelection';

const pageSize = 20;

export default function Patients() {
    const [selectedOnly, setSelectedOnly] = useState(false);
    const [groupPatients, setGroupPatients] = useState(false);
    const [showCoAdministerDialog, setShowCoAdministerDialog] = useState('');
    const [routeType, setRouteType] = useState(routeTypes.COVID);

    const {
        selectedPatientIds,
        setSelectedPatientIds,
        patients,
        selectedPatients,
        select,
        deselect,
        selectAll,
        deselectAll,
        getPatients,
        overrides,
        setOverrides,
        performSort,
        setSearch,
        search,
        onClearSearch,
        filters,
        updateFilters,
        onOverrideJobStatus,
        changeSortKey,
        sortKey,
        sortDirection,
        page,
        setPage,
        practiceOptions,
    } = usePatientsContext();

    const { pathname } = useLocation();
    const history = useHistory();

    const {
        RootStore: { configStore },
    } = useStores();

    const { jobId } = useParams<{ jobId?: string }>();

    const onToggleSelect = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = currentTarget;

        if (checked) {
            select(id);
        } else {
            deselect(id);
        }
    };

    const onToggleSelectAll = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = currentTarget;

        if (checked) {
            selectAll();
        } else {
            deselectAll();
        }
    };

    const allSelected = useMemo(
        () =>
            selectedPatientIds.length > 0 &&
            patients
                .filter(({ selectable }) => selectable)
                .every(({ id }) => selectedPatientIds.includes(id)),
        [patients, selectedPatientIds],
    );

    const checkPatientCompatibility = (url: string) => {
        const patientsCompatible = arePatientsCompatible(selectedPatients);

        if (patientsCompatible) {
            history.push(url, { closeModal: true });
        } else {
            setShowCoAdministerDialog(url);
        }
    };

    const onCreateRoutes = () => {
        checkPatientCompatibility('/vaccinations/routes/new');
    };

    const onAddToRoute = () => {
        checkPatientCompatibility('/vaccinations/routes/add-to-existing');
    };

    const onConfirmRouteType = () => {
        let filteredPatients: Vaccination[] = [];

        if (routeType === routeTypes.COVID) {
            filteredPatients = filterPatientSelection(
                selectedPatients,
                FilterType.COVID_PLUS_CO_ADMIN,
            );
        } else if (routeType === routeTypes.NON_COVID) {
            filteredPatients = filterPatientSelection(
                selectedPatients,
                FilterType.NON_COVID_NO_CO_ADMIN,
            );
        } else if (routeType === routeTypes.NON_COVID_PLUS_CO_ADMIN) {
            filteredPatients = filterPatientSelection(
                selectedPatients,
                FilterType.NON_COVID_PLUS_CO_ADMIN,
            );
        } else {
            filteredPatients = [...selectedPatients];
        }

        setSelectedPatientIds(filteredPatients.map((patient) => patient.id));
        history.push(showCoAdministerDialog, { routeType, closeModal: true });
        setShowCoAdministerDialog('');
    };

    const onToggleGroupPatients = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const sort = currentTarget.checked ? 'nhsNumber' : '';
        setOverrides({ ...overrides, sort });
        setGroupPatients(currentTarget.checked);
    };

    const onSubmitSearch = (event: React.FormEvent) => {
        event.preventDefault();
        updateFilters();

        if (pathname !== '/vaccinations/patients') {
            history.push('/vaccinations/patients');
        }
    };

    const startIndex = pageSize * (page - 1);
    const pageCount = Math.ceil(patients.length / pageSize) || 1;

    let patientsToList = patients.sort(performSort).slice(startIndex, startIndex + pageSize);

    if (selectedOnly) {
        patientsToList = patientsToList.filter((patient) =>
            selectedPatientIds.includes(patient.id),
        );
    }

    const isLoading =
        getPatients.networkStatus === NetworkStatus.loading ||
        getPatients.networkStatus === NetworkStatus.refetch ||
        getPatients.networkStatus === NetworkStatus.setVariables;

    return (
        <>
            <nav className="vaccinations__navigation">
                <div className="vaccinations__navigation-info">
                    <span>
                        Found {patients.length} results
                        {patients.length > 0 && (
                            <>
                                {' '}
                                | Page {page} of {pageCount}
                            </>
                        )}
                    </span>
                    <span className="vaccinations__selected-count">
                        {selectedPatientIds.length} selected
                    </span>
                </div>
                <div>
                    <Button
                        className="vaccinations__navigation-button"
                        text="Refresh"
                        icon="refresh"
                        onClick={() => getPatients.refetch()}
                        disabled={isLoading}
                        outlined
                    />
                    <Button
                        className="vaccinations__navigation-button"
                        text="Create routes"
                        intent="success"
                        icon="add-to-artifact"
                        onClick={onCreateRoutes}
                        disabled={selectedPatientIds.length === 0}
                        outlined
                    />
                    <Button
                        text="Add to existing route"
                        intent="primary"
                        icon="new-link"
                        onClick={onAddToRoute}
                        disabled={selectedPatientIds.length === 0}
                        outlined
                    />
                </div>
                <ButtonGroup minimal>
                    <Button
                        icon="chevron-left"
                        text="Previous"
                        disabled={page === 1}
                        intent="primary"
                        onClick={() => setPage(page - 1)}
                    />
                    <Button
                        rightIcon="chevron-right"
                        text="Next"
                        disabled={page === pageCount}
                        intent="primary"
                        onClick={() => setPage(page + 1)}
                    />
                </ButtonGroup>
            </nav>
            <aside className="vaccinations__tools">
                <h3 className="h3">Search</h3>
                <form onSubmit={onSubmitSearch} className="search">
                    <FormGroup helperText="Search by NHS number, name, address, contact number, practice or notes.">
                        <InputGroup
                            onChange={(event) => setSearch(event.currentTarget.value)}
                            value={search}
                            placeholder="Enter search text..."
                            rightElement={
                                <Button
                                    onClick={onClearSearch}
                                    icon="cross"
                                    intent="primary"
                                    minimal
                                />
                            }
                        />
                    </FormGroup>
                </form>
                <h3 className="h3">Filters</h3>
                <Filters
                    filterOptions={filterOptions(configStore, filters, practiceOptions)}
                    filters={filters}
                    setFilters={(nextFilters: any) => updateFilters({ nextFilters })}
                />
                <h3 className="h3">Mode</h3>
                <Switch
                    checked={selectedOnly}
                    onChange={(event) => setSelectedOnly(event.currentTarget.checked)}
                    alignIndicator="right"
                    className={selectedOnly ? '' : 'filter__label--any'}
                >
                    Show selected patients only
                </Switch>
                <Switch
                    checked={groupPatients}
                    onChange={onToggleGroupPatients}
                    alignIndicator="right"
                    className={groupPatients ? '' : 'filter__label--any'}
                >
                    Group patient vaccinations
                </Switch>
                <Switch
                    checked={!!overrides.filters.jobStatus?.includes(JobStatus.COMPLETED)}
                    onChange={({ currentTarget }) =>
                        onOverrideJobStatus(currentTarget.checked, JobStatus.COMPLETED)
                    }
                    alignIndicator="right"
                    className={
                        overrides.filters.jobStatus?.includes(JobStatus.COMPLETED)
                            ? ''
                            : 'filter__label--any'
                    }
                >
                    Completed vaccinations
                </Switch>
                <Switch
                    checked={!!overrides.filters.jobStatus?.includes(JobStatus.CONTROLLER_ABORTED)}
                    onChange={({ currentTarget }) =>
                        onOverrideJobStatus(currentTarget.checked, JobStatus.CONTROLLER_ABORTED)
                    }
                    alignIndicator="right"
                    className={
                        overrides.filters.jobStatus?.includes(JobStatus.CONTROLLER_ABORTED)
                            ? ''
                            : 'filter__label--any'
                    }
                >
                    Archived vaccinations
                </Switch>
                <div className="vaccinations__tools-buttons">
                    <Link
                        className="bp5-button bp5-intent-success bp5-fill bp5-large bp5-outlined vaccinations__tools-button"
                        to="/vaccinations/patients/add"
                    >
                        <Icon icon="add" />
                        <span className="bp5-button-text">Add patient</span>
                    </Link>
                    <Link
                        className="bp5-button bp5-intent-success bp5-fill bp5-large bp5-outlined vaccinations__tools-button"
                        to="/vaccinations/patients/import"
                    >
                        <Icon icon="cloud-upload" />
                        <span className="bp5-button-text">Import patients</span>
                    </Link>
                </div>
            </aside>
            <main className="vaccinations__main">
                {getPatients.error && (
                    <Callout intent="danger">{getPatients.error.message}</Callout>
                )}
                {isLoading && <Loader fullscreen={false} />}
                {patients && patients.length > 0 && (
                    <PatientsTable
                        patients={patientsToList}
                        selectedPatientIds={selectedPatientIds}
                        onToggleSelect={onToggleSelect}
                        allSelected={allSelected}
                        onToggleSelectAll={onToggleSelectAll}
                        changeSortKey={changeSortKey}
                        sortDirection={sortDirection}
                        sortKey={sortKey}
                        groupPatients={groupPatients}
                    />
                )}
                <Alert
                    isOpen={showCoAdministerDialog.length > 0}
                    onConfirm={onConfirmRouteType}
                    onCancel={() => setShowCoAdministerDialog('')}
                    cancelButtonText="Cancel"
                    confirmButtonText="Confirm"
                    icon="help"
                    intent="primary"
                >
                    <p>
                        <strong>Please choose route type</strong>
                    </p>
                    <p>
                        The selection includes both COVID-19 vaccines and other vaccines that cannot
                        be co-administered with COVID-19.
                    </p>
                    <RadioGroup
                        label="Please select the type of route you would like to create:"
                        onChange={({ currentTarget }) => setRouteType(currentTarget.value)}
                        selectedValue={routeType}
                    >
                        <Radio value={routeTypes.COVID}>
                            <strong>COVID-19</strong> plus other vaccines that can be
                            co-administered with COVID-19
                        </Radio>
                        <Radio value={routeTypes.NON_COVID}>
                            <strong>Vaccines other than COVID-19</strong> that cannot be
                            co-administered with COVID-19
                        </Radio>
                        <Radio value={routeTypes.NON_COVID_PLUS_CO_ADMIN}>
                            <strong>Vaccines other than COVID-19</strong> including those that could
                            be co-administered with COVID-19 (note: this could result in more visits
                            to a patient than necessary)
                        </Radio>
                        <Radio value={routeTypes.ANY}>
                            <strong>Any vaccines</strong> (note: this could result in more visits to
                            a patient than necessary)
                        </Radio>
                    </RadioGroup>
                </Alert>
            </main>
            {jobId && (
                <div className="vaccinations__modal">
                    <PatientDetails id={jobId} />
                </div>
            )}
            {pathname.endsWith('/add') && (
                <div className="vaccinations__modal">
                    <AddPatient />
                </div>
            )}
            {pathname.endsWith('/import') && (
                <div className="vaccinations__modal">
                    <ImportPatients refetch={getPatients.refetch} />
                </div>
            )}
        </>
    );
}
